<template>
  <div
    class="bg-onboarding-background relative z-0 flex min-h-screen flex-col items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-24"
  >
    <div
      class="relative z-20 mx-6 flex min-h-[40rem] w-full flex-col justify-between rounded-3xl border-b-4 border-l border-r border-t border-black bg-white p-6 sm:p-8 md:w-full md:rounded-2xl lg:max-w-[640px]"
    >
      <div class="flex flex-col items-center">
        <slot name="header" /> <slot name="content" />
      </div>
      <div class="flex flex-col items-center">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
